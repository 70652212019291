import type { ReactElement } from 'react';
import { Clipboard } from '@phosphor-icons/react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toastMessage } from '~/global/utils/toast';

export interface GlobalCopyToClipboardProps {
  children?: ReactElement | ReactElement[] | string;
  className?: string;
  icon?: boolean;
  message?: string | ReactElement;
  text: string;
}

/**
 * @name GlobalCopyToClipboard
 * @description Copy to clipboard component, useful wrapper around things
 * like a "users email address" that we might want to use for a search
 */
export const GlobalCopyToClipboard = (props: GlobalCopyToClipboardProps) => {
  const {
    children,
    className,
    icon = true,
    message = `📋 Copied to clipboard.`,
    text,
  } = props;

  // Handlers
  const onCopyHandler = () => {
    toastMessage(message);
  };

  const handleOnClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <CopyToClipboard onCopy={onCopyHandler} text={text}>
      <button className={className} onClick={handleOnClick}>
        {children && children}
        {icon && <Clipboard className="m-0" height={16} width={16} />}
      </button>
    </CopyToClipboard>
  );
};
